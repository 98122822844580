.master-card {
	position: absolute;
	border-radius: 10px;
	background: #e9790bc7;
	top: calc(50% - 250px);
	left: calc(50% - 200px);
	width: 400px;
	height: 500px;
	filter: blur(1.5px);
	-webkit-filter: blur(1.5px);
}

.master-card-text {
	display: grid;
	color: white;
	font-weight: bold;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	padding: 20px;
	text-align: center;
}
