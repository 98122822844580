body {
	/* line-height: 1!important; */
	background-image: url("https://kotzevs-v2.s3.eu-central-1.amazonaws.com/photos/zeus_background.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
}

.header {
	position: fixed;
	margin: 20px 0 0 0;
	z-index: 999;
	background-color: #ffc107;
	left: 10%;
	width: 80%;
}

.header h1 {
	position: relative;
	z-index: 999;
	margin-top: 0;
	margin-left: 50px;
	margin-bottom: 0 !important;
}
