@keyframes tonext {
	75% {
		left: 0;
	}
	95% {
		left: 100%;
	}
	98% {
		left: 100%;
	}
	99% {
		left: 0;
	}
}

@keyframes tostart {
	75% {
		left: 0;
	}
	95% {
		left: -300%;
	}
	98% {
		left: -300%;
	}
	99% {
		left: 0;
	}
}

@keyframes snap {
	96% {
		scroll-snap-align: center;
	}
	97% {
		scroll-snap-align: none;
	}
	99% {
		scroll-snap-align: none;
	}
	100% {
		scroll-snap-align: center;
	}
}

* {
	box-sizing: border-box;
	scrollbar-color: transparent transparent; /* thumb and track color */
	scrollbar-width: 0px;
	-ms-overflow-style: none;
}

*::-webkit-scrollbar {
	width: 0;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background: transparent;
	border: none;
}

ol,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

.carousel {
	position: absolute !important;
	width: 100%;
	height: 102%;
	top: 0;
	left: 0;
}

.carousel__viewport {
	display: flex;
	counter-reset: item; /* ???????????????????? */
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
	width: 100%;
	height: 100%;
	overflow-x: scroll;
}

.carousel__slide {
	position: relative;
	flex: 0 0 100%;
	width: 100%;
	counter-increment: item;
	background-color: #ff9c24;
}

.carousel__slide:before {
	/* content: counter(item); */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -40%, 70px);
	color: #fff;
	font-size: 2em;
}

.carousel__snapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	scroll-snap-align: center;
}

@media (hover: hover) {
	.carousel__slide:last-child .carousel__snapper {
		animation-name: tostart, snap;
	}
}

@media (prefers-reduced-motion: reduce) {
	.carousel__snapper {
		animation-name: none;
	}
}

.carousel:hover .carousel__snapper,
.carousel:focus-within .carousel__snapper {
	animation-name: none;
}

.carousel::before,
.carousel::after,
.carousel__prev,
.carousel__next {
	position: absolute;
	top: 0;
	margin-top: 25%;
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	font-size: 0;
	outline: 0;
}

.carousel::before,
.carousel__prev {
	left: 0.5rem;
}

.carousel::after,
.carousel__next {
	right: 0.5rem;
}

.carousel::before,
.carousel::after {
	content: "";
	z-index: 1;
	background-color: #e9790b;
	background-size: 1.5rem 1.5rem;
	background-repeat: no-repeat;
	background-position: center center;
	color: #fff;
	font-size: 2.5rem;
	line-height: 4rem;
	text-align: center;
	pointer-events: none;
}

.carousel::before {
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
}

.carousel::after {
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
}

.photo {
	background-size: cover;
	background-repeat: no-repeat;
	display: block;
	margin-right: auto;
	margin-left: auto;
	max-width: 100%;
	max-height: 100%;
}
